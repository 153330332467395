/* global google OverlappingMarkerSpiderfier moment GLOBAL_CONSTANTS */
import { debounce } from 'lodash'

class Tracking {
  init () {
    this.map = new google.maps.Map(document.getElementById('map'), {
      center: { lat: 24.74, lng: 46.72 },
      zoom: 8
    })
    this.oms = new OverlappingMarkerSpiderfier(this.map, {
      markersWontMove: true,
      markersWontHide: true,
      basicFormatEvents: true
    })
    this.markers = []

    this.form = $('form#map-filter')
    this.allField = $('#count-label-all')
    this.onlineField = $('#count-label-online')
    this.offlineField = $('#count-label-offline')
    this.idleField = $('#count-label-idle')
    this.activeField = $('#count-label-active')
    this.mapInfoWindowTemplate = $('#map-info-window-template')
    this.heatmapToggleButton = $('#heatmap-toggle-button')
    this.heatmapProgressIndicator = $('#heatmap-progress-indicator')
    this.searchBox = $('#search')
    this.cityFilter = $('#city_filter')
    this.couriers = []
    this.infoWindow = null
    this.heatmapData = new google.maps.MVCArray([])
    this.isHeatmapEnabled = this.heatmapToggleButton.is(':checked')
    this.boundingBox = []
    this.boundingBoxArea = 0

    this.gradient = [
      'rgba(0, 255, 255, 0)',
      'rgba(0, 190, 214, 1)',
      'rgba(0, 190, 214, 1)',
      'rgba(0, 190, 214, 1)',
      'rgba(225, 216, 64, 1)',
      'rgba(225, 216, 64, 1)',
      'rgba(225, 216, 64, 1)',
      'rgba(127, 0, 63, 1)',
      'rgba(127, 0, 63, 1)',
      'rgba(127, 0, 63, 1)',
      'rgba(191, 0, 31, 1)',
      'rgba(191, 0, 31, 1)',
      'rgba(191, 0, 31, 1)',
      'rgba(255, 0, 0, 1)',
      'rgba(255, 0, 0, 1)',
      'rgba(255, 0, 0, 1)'
    ]
    this.heatmap = new google.maps.visualization.HeatmapLayer({
      data: this.heatmapData
    })
    this.heatmap.set('gradient', this.gradient)
    this.heatmap.setMap(this.map)

    this.heatmapToggleButton.on('change', () => {
      this.toggleHeatmap()
    })

    this.getCouriersTrackData(GLOBAL_CONSTANTS.COURIERS_TRACKING_INDEX_PATH)
    setInterval(() => {
      this.getCouriersTrackData(GLOBAL_CONSTANTS.COURIERS_TRACKING_INDEX_PATH)
      if (this.isHeatmapEnabled) {
        this.getCouriersOrdersData(GLOBAL_CONSTANTS.COURIERS_ORDERS_INDEX_PATH)
      }
    }, 120000)

    this.form.on('change', () => {
      this.getCouriersTrackData(GLOBAL_CONSTANTS.COURIERS_TRACKING_INDEX_PATH)
      if (this.isHeatmapEnabled) {
        this.getCouriersOrdersData(GLOBAL_CONSTANTS.COURIERS_ORDERS_INDEX_PATH)
      }
    })
    this.searchBox.on('input', (e) => this.searchCouriers(e))
    $(document).on('click', '.courier', (event) => {
      this.displayInfoWindow(event, event.currentTarget)
    })

    google.maps.event.addListenerOnce(this.map, 'idle', () => {
      $('.gm-style div[aria-label="Map"] > div:nth-of-type(1) > div:last-of-type')
        .addClass('brightness-75')
    })
  }

  toggleHeatmap () {
    if (this.heatmap.getData().getArray().length) {
      this.heatmap.setData([])
      this.heatmap.setMap(this.map)
    } else {
      this.setMapBoundingBox()
      this.getCouriersOrdersData(GLOBAL_CONSTANTS.COURIERS_ORDERS_INDEX_PATH)

      google.maps.event.addListener(this.map, 'bounds_changed', debounce(() => {
        this.setMapBoundingBox()
        this.getCouriersOrdersData(GLOBAL_CONSTANTS.COURIERS_ORDERS_INDEX_PATH)
      }, 1500))
    }
  }

  setMapBoundingBox () {
    const boundingBoxJSON = this.map.getBounds().toJSON()
    this.boundingBox = [
      boundingBoxJSON.south, boundingBoxJSON.west, boundingBoxJSON.north, boundingBoxJSON.east
    ]

    const bounds = this.map.getBounds()
    const ne = bounds.getNorthEast()
    const sw = bounds.getSouthWest()
    const nw = new google.maps.LatLng(ne.lat(), sw.lng())
    const se = new google.maps.LatLng(sw.lat(), ne.lng())

    // Area unit is square kilometers
    this.boundingBoxArea = Math.round(
      google.maps.geometry.spherical.computeArea([ne, nw, sw, se]) / (1000000)
    )
  }

  searchCouriers (e) {
    const val = e.target.value
    this.search(val)
  }

  filterCouriers (e) {
    const val = e.target.value
    this.filterByCity(val)
  }

  displayInfoWindow (event, element) {
    event.stopPropagation()
    const id = $(element).find('.id').val()
    this.clickUser(id)
  }

  getCouriersTrackData (locationUrl) {
    const params = this.form.serialize()
    fetch(`${locationUrl}?${params}`)
      .then(response => response.json())
      .then((data) => this.processCouriersTrackData(data))
  }

  getCouriersOrdersData (locationUrl) {
    let label = 'recruiter_tools.tracking_map.loading_orders'

    if (this.boundingBoxArea < 300000) {
      fetch(`${locationUrl}?bounding_box=${encodeURIComponent(JSON.stringify(this.boundingBox))}`)
        .then(response => response.json())
        .then((data) => {
          this.processCouriersOrdersData(data)
          label = data.data.couriers_orders.length > 0
            ? 'recruiter_tools.tracking_map.orders_loaded'
            : 'recruiter_tools.tracking_map.loaded_with_no_orders'
        })
    } else {
      this.processCouriersOrdersData({ data: { couriers_orders: [] } })
      label = 'recruiter_tools.tracking_map.area_too_large'
    }

    this.heatmapProgressIndicator.text(I18n.t(label))
  }

  processCouriersTrackData ({ data }) {
    this.updateCouriersCounts(data.couriers_counts)
    this.deleteAllMarkers()
    data.couriers_tracking_list.forEach((track) => this.setMarker(track))
    if (data.couriers_tracking_list.length) {
      this.fitMapBounds(data.couriers_tracking_list)
    }
    this.fillUserBox(data.couriers_tracking_list)
    this.couriers = data.couriers_tracking_list
  }

  processCouriersOrdersData ({ data }) {
    this.heatmap.setData([])
    data.couriers_orders.forEach((order) => {
      this.heatmapData.push(new google.maps.LatLng(order.latitude, order.longitude))
      this.heatmap.setData(this.heatmapData)
    })
  }

  updateCouriersCounts (counts) {
    this.allField.text(counts.all)
    this.onlineField.text(counts.online)
    this.offlineField.text(counts.offline)
    this.idleField.text(counts.idle)
    this.activeField.text(counts.active)
  }

  setMarker (courierTrack) {
    const marker = this.addMarker(
      {
        lat: parseFloat(courierTrack.latitude),
        lng: parseFloat(courierTrack.longitude)
      },
      courierTrack.status
    )

    marker.addListener('click', () => {
      this.infoWindow != null && this.infoWindow.close()
      this.infoWindow = new google.maps.InfoWindow({
        content: this.buildInfoWindow(courierTrack)
      })
      this.infoWindow.open({
        anchor: marker,
        map: this.map,
        shouldFocus: false
      })
    })

    this.oms.addMarker(marker)
  }

  addMarker (position, status) {
    const map = this.map
    const icon = this.getIcon(status)
    const marker = new google.maps.Marker({
      map,
      position,
      icon: {
        url: icon,
        scaledSize: new google.maps.Size(64, 64)
      }
    })
    this.markers.push(marker)
    return marker
  }

  setMapOnAllMarkers (map) {
    for (let i = 0; i < this.markers.length; i++) {
      this.markers[i].setMap(map)
    }
  }

  hideMarkers () {
    this.setMapOnAllMarkers(null)
  }

  showMarkers () {
    this.setMapOnAllMarkers(this.map)
  }

  deleteAllMarkers () {
    this.hideMarkers()
    this.markers = []
  }

  buildInfoWindow (courier) {
    const color = this.getColor(courier.status)

    // Reset status
    this.mapInfoWindowTemplate.find(`[data="status-${courier.status.toLowerCase()}"]`)
      .parent()
      .removeClass((event, e) => {
        return (e.match(/text-.*/g) || []).join(' ')
      })
    this.mapInfoWindowTemplate.find('[data="status-indicator"]')
      .removeClass((event, e) => {
        return (e.match(/bg-.*/g) || []).join(' ')
      })
    this.mapInfoWindowTemplate.find('.status').addClass('hidden')

    // Set current status
    this.mapInfoWindowTemplate.find('[data="status-indicator"]').addClass(`bg-${color}`)
    this.mapInfoWindowTemplate.find(`[data="status-${courier.status.toLowerCase()}"]`)
      .removeClass('hidden')
    this.mapInfoWindowTemplate.find(`[data="status-${courier.status.toLowerCase()}"]`)
      .parent().addClass(`text-${color}`)
    this.mapInfoWindowTemplate.find('[data="profile-pic"]').attr('src', courier.vProfilePic)
    this.mapInfoWindowTemplate.find('[data="full-name"]').text(courier.vFullName)
    this.mapInfoWindowTemplate.find('[data="full-name"]').attr('title', courier.vFullName)
    this.mapInfoWindowTemplate.find('[data="phone"]').text(courier.vPhone)
    this.mapInfoWindowTemplate.find('[data="phone"]').attr('href', `tel:${courier.vPhone}`)
    this.mapInfoWindowTemplate.find('[data="balance"]').text(courier.fAccountBalance)
    this.mapInfoWindowTemplate.find('[data="last-order"]')
      .text(moment(courier.last_delivered_order_at).fromNow())
    this.mapInfoWindowTemplate.find('[data="total-delivered-orders"]')
      .text(courier.iTotalOrderDelivered)

    return this.mapInfoWindowTemplate.html()
  }

  fitMapBounds (trackingList) {
    const latlng = trackingList.map((track) => new google.maps.LatLng(
      parseFloat(track.latitude), parseFloat(track.longitude)
    ))
    const bounds = new google.maps.LatLngBounds()
    for (let i = 0; i < latlng.length; i++) {
      bounds.extend(latlng[i])
    }
    this.map.fitBounds(bounds)
  }

  getIcon (status) {
    let icon
    switch (status) {
      case 'online':
        icon = '/images/recruiter_tools/status/online.svg'
        break
      case 'offline':
        icon = '/images/recruiter_tools/status/offline.svg'
        break
      case 'active':
        icon = '/images/recruiter_tools/status/active.svg'
        break
      case 'idle':
        icon = '/images/recruiter_tools/status/idle.svg'
        break
      default:
        icon = '/images/recruiter_tools/status/active.svg'
        break
    }
    return icon
  }

  generateUserBox (courier, color) {
    moment.locale(this.getSelectedLocale())

    /* eslint-disable max-len */
    return `
      <div class="bg-white w-full flex items-center border-b border-gray-100 pb-3 mb-4 hover:bg-gray-50 p-2 md:p-4  cursor-pointer courier">
        <div class="relative flex items-center me-2">
          <input type="hidden" class="id" value="${courier.id}">
          <img src=${courier.vProfilePic} alt="My profile" class="w-16 h-16 rounded-full">
          <span class="absolute h-5 w-5 rounded-full bottom-0 end-0 border-2 border-white bg-${color}"></span>
        </div>
        <div class="flex-grow">
          <div class="font-semibold text-gray-700">
            ${courier.vFullName}
          </div>
          <div class="text-sm text-gray-500 text-start" dir="ltr">
            ${courier.vPhone}
          </div>
          <div class="text-sm text-gray-500 text-start ${courier.last_delivered_order_at ? '' : 'hidden'}">
            ${I18n.t('recruiter_tools.tracking_map.last_order')}
            <span class="text-start" dir="ltr" title="${moment(courier.last_delivered_order_at).format('LLLL')}">
              ${moment(courier.last_delivered_order_at).fromNow()}
            </span>
          </div>
        </div>
      </div>
    `
    /* eslint-enable max-len */
  }

  fillUserBox (couriers) {
    $('.user-box').empty()
    couriers.forEach((courier) => {
      const color = this.getColor(courier.status)
      $('.user-box').append(this.generateUserBox(courier, color))
    })
  }

  search (value) {
    const filteredCouriers = this.couriers.filter((courier) => {
      return courier.full_name.toLowerCase().includes(value.toLowerCase()) ||
        courier.phone.includes(value) ||
        courier.id === value
    })
    this.deleteAllMarkers()
    filteredCouriers.forEach((courier) => this.setMarker(courier))
    this.fillUserBox(filteredCouriers)
  }

  clickUser (id) {
    this.infoWindow != null && this.infoWindow.close()
    const courier = this.couriers.find((courier) => {
      return courier.id === Number(id)
    })
    const lat = parseFloat(courier.latitude)
    const lng = parseFloat(courier.longitude)
    this.map.setCenter({ lat, lng })
    this.infoWindow = new google.maps.InfoWindow({
      content: this.buildInfoWindow(courier)
    })

    const marker = this.markers.find((marker) => {
      return lat === marker.getPosition().lat() && lng === marker.getPosition().lng()
    })

    this.infoWindow.open({
      anchor: marker,
      map: this.map,
      shouldFocus: false
    })
  }

  getColor (status) {
    let color
    switch (status) {
      case 'online':
        color = 'primary'
        break
      case 'offline':
        color = 'red-400'
        break
      case 'active':
        color = 'green-400'
        break
      case 'idle':
        color = 'yellow-400'
        break
      default:
        color = 'green-400'
        break
    }
    return color
  }

  getSelectedLocale () {
    return $('html').attr('lang')
  }
}

export default Tracking
